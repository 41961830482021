import React from "react"
import WiczlinoHeader from "../../../components/estates/newwiczlino/wiczlinoHeader"
import Search from "../../../components/search/search"
import Description from "../../../components/estates/newwiczlino/description"
import Visual from "../../../components/estates/newwiczlino/visual"
import LogBuilding from "../../../components/estates/logBuilding"
import Localization from "../../../components/estates/newwiczlino/localization"
import EstatePlan from "../../../components/estates/newwiczlino/estatePlan"
import Layout from "../../../components/layout"
import Helmet from "react-helmet"
import Modal from "../../../components/popup2/popup"
import { logs } from "../../../data/diary/buildingLogsOGW2"
import EstateVisualDownload from "@/components/estates/estateVisualDownload"
import EstateDisclaimer from "@/components/estates/estateDisclaimer"
import EstateHeader from "@/components/estates/estateHeader"
import JanowoTwoVisual from "@/components/estates/janowotwo/janowoTwoVisual"
import wizImage from "@/images/z-8/z-7i.jpg"
import wiz1a from "@/images/z-19/UJECIE1v3.jpg"
import wiz2a from "@/images/z-19/UJECIE4v3.jpg"
import wiz5b from "@/images/wiczlino-2/z6g.jpg"

function Wiczlinodwa({ location }) {
  const scrollDestination =
    location && location.state && location.state.position
  return (
    <>
      <Helmet>
        <title>WILLBUD | Osiedle Gdynia Wiczlino II (Etap IIIA)</title>
      </Helmet>
      <Layout>
        <EstateHeader
          title={`Osiedle Gdynia Wiczlino II`}
          place={"GDYNIA, UL. KORMORANA"}
          price={7250}
          inProgress
        />
        <JanowoTwoVisual
          slides={[wizImage, wiz1a, wiz2a, wiz5b]}
          alt={`Osiedle Gdynia Wiczlino II Wizualizacja`}
        />

        <WiczlinoHeader scrollDestination={scrollDestination} />

        <Description />
        <Visual />
        <EstateVisualDownload />

        <Search estate={"wiczlino2"} />
        <EstatePlan scrollDestination={scrollDestination} />
        <Localization estate={"wiczlino2"} />
        <LogBuilding
          logs={logs.filter(l => l.etap)}
          anchor={`/pl/oferta/osiedle-gdynia-wiczlino-2/#diary`}
        />
        <EstateDisclaimer />
        <Modal />
      </Layout>
    </>
  )
}

export default Wiczlinodwa
